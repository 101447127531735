export const ProductBreakPoints = {
  320: { slidesPerView: 2.2, spaceBetween: 16 },
  371: { slidesPerView: 2.5, spaceBetween: 16 },
  531: { slidesPerView: 3.5, spaceBetween: 16 },
  992: { slidesPerView: 4, spaceBetween: 16 },
  1024: { slidesPerView: 4.5, spaceBetween: 20 },
  1300: { slidesPerView: 6, spaceBetween: 20 },
};

export const MultiBreakPoints = {
  320: { slidesPerView: 1.7, spaceBetween: 16 },
  421: { slidesPerView: 2.5, spaceBetween: 16 },
  551: { slidesPerView: 3, spaceBetween: 16 },
  992: { slidesPerView: 4, spaceBetween: 25 },
};

export const GridBreakPoints = {
  320: {
    slidesPerView: 1,
    grid: {
      fill: 'row',
      rows: 3,
    },
    spaceBetween: 15,
  },
  421: {
    slidesPerView: 1.1,
    grid: {
      fill: 'row',
      rows: 3,
    },
    spaceBetween: 15,
  },
  471: {
    slidesPerView: 1.3,
    grid: {
      fill: 'row',
      rows: 3,
    },
    spaceBetween: 15,
  },
  641: {
    slidesPerView: 1.6,
    grid: {
      fill: 'row',
      rows: 3,
    },
    spaceBetween: 20,
  },
  769: {
    slidesPerView: 2,
    grid: {
      fill: 'row',
      rows: 3,
    },
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 3,
    grid: {
      fill: 'row',
      rows: 2,
    },
    spaceBetween: 20,
  },
};

export const BrandBreakPoint = {
  320: { slidesPerView: 2.5, spaceBetween: 10 },
  401: { slidesPerView: 3, spaceBetween: 10 },
  501: { slidesPerView: 3.5, spaceBetween: 10 },
  641: { slidesPerView: 4, spaceBetween: 10 },
  769: { slidesPerView: 5, spaceBetween: 10 },
  1024: { slidesPerView: 6, spaceBetween: 10 },
};

import { Grid, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GridBreakPoints } from '@/constants/BreakPoints';

import ProductTitle from './ProductTitle';
import ProductCard from '../cards/ProductCard';
import { useRouter } from 'next/router';
import { useState } from 'react';

const GridSwiper = ({ data }) => {
  const activeData = data?.value?.filter((item) => item?.active);
  const curCategory = activeData?.[0];
  const { locale } = useRouter();
  const [swiper, set_swiper] = useState(false);

  return (
    activeData?.length > 0 && (
      <>
        <ProductTitle
          title={curCategory?.[`title-${locale}`]}
          showMoreLink={`/${curCategory?.url}`}
          swiper={swiper}
        />
        <Swiper
          key={locale}
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          modules={[Grid, Autoplay]}
          breakpoints={GridBreakPoints}
          loop
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onInit={(e) => set_swiper(e)}
        >
          {curCategory?.products?.map((product, i) => (
            <SwiperSlide key={i}>
              <ProductCard product={product} fromGrid />
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    )
  );
};

export default GridSwiper;

import { useRef, useState } from 'react';
import { useRouter } from 'next/router';

import Link from 'next/link';
import ArrowIcon from '../icons/ArrowIcon';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const ProductTitle = ({
  title,
  isMulti,
  showMoreLink,
  swiper,
  isSwiper = true,
  tabs,
  changeTabHandler,
}) => {
  const ref = useRef();
  const { locale } = useRouter();
  const [open, setOpen] = useState(false);
  useOnClickOutside([ref], () => setOpen(false));

  const [activeTab, SetActiveTab] = useState(tabs?.[0]?.id);

  return (
    <div
      className={`mb-3 flex justify-between ${
        isMulti
          ? 'max-sm:flex-col max-sm:gap-3 sm:items-center'
          : 'items-center'
      } md:mb-5 md:mt-11`}
    >
      <h2 className='text-sm font-medium sm:text-xl'>{title}</h2>
      <div className='flex items-center gap-5 max-sm:justify-between'>
        {isMulti ? (
          <div className='relative'>
            <button
              onClick={() => setOpen(!open)}
              className='hidden w-60 items-center justify-between rounded-md border px-4 py-2 max-sm:flex'
            >
              <span className='text-sm'>
                {
                  tabs?.find((item) => item?.id === activeTab)?.[
                    `title-${locale}`
                  ]
                }
              </span>
              <ArrowIcon />
            </button>
            <ul
              ref={ref}
              className={`items-center ${
                open ? 'flex' : 'hidden'
              } gap-6 max-sm:absolute max-sm:start-0 max-sm:top-full max-sm:z-50 max-sm:w-full max-sm:flex-col max-sm:items-start max-sm:rounded-md max-sm:border max-sm:bg-white max-sm:px-4 max-sm:py-2 sm:flex`}
            >
              {tabs?.map((item, key) => (
                <li
                  key={key}
                  onClick={() => {
                    setOpen(false);
                    SetActiveTab(item?.id);
                    changeTabHandler(item?.id);
                  }}
                  className={` cursor-pointer border-b text-sm  font-medium hover:border-light-green hover:text-primary ${activeTab === item?.id ? 'border-light-green text-primary' : 'border-transparent  text-dark-gray'}`}
                >
                  {item?.[`title-${locale}`]}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className='flex items-center gap-7 sm:gap-14'>
          {showMoreLink ? (
            <Link
              href={showMoreLink}
              className='text-xs font-medium text-primary sm:text-sm'
            >
              {locale === 'en' ? 'See All' : 'مشاهدة الكل'}
            </Link>
          ) : null}
          {isSwiper && (
            <div dir='ltr' className='flex items-center gap-3 sm:gap-5'>
              <button onClick={() => swiper?.slideNext()}>
                <ArrowIcon className='rotate-90 cursor-pointer' />
              </button>
              <button onClick={() => swiper?.slidePrev()}>
                <ArrowIcon className='-rotate-90 cursor-pointer' />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductTitle;

import CategoryCard from '../cards/CategoryCard';
import ProductTitle from './ProductTitle';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { useRouter } from 'next/router';

const Categories = ({ data }) => {
  const [swiper, set_swiper] = useState(false);
  const { locale } = useRouter();

  const { t } = useTranslation('home');
  const activeData = data?.value?.filter((item) => item?.active);

  return (
    activeData?.length > 0 && (
      <>
        <ProductTitle title={t('Shop By Category')} swiper={swiper} showMoreLink={'/categories'} />
        <Swiper
          key={locale}
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          breakpoints={{
            320: { slidesPerView: 3, spaceBetween: 10 },
            400: { slidesPerView: 3.5, spaceBetween: 10 },
            500: { slidesPerView: 4, spaceBetween: 10 },
            769: { slidesPerView: 5, spaceBetween: 10 },
            1024: { slidesPerView: 6, spaceBetween: 20 },
          }}
          loop
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onInit={(e) => set_swiper(e)}
          className='px-0.5'
        >
          {activeData?.map((cat, idx) => (
            <SwiperSlide key={idx}>
              <CategoryCard itemData={cat} />
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    )
  );
};

export default Categories;

import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BrandBreakPoint } from '@/constants/BreakPoints';

import Image from '../Image';
import ProductTitle from './ProductTitle';
import { useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { renderImageHandler } from '@/helpers/common/renderImage';
import Link from 'next/link';

const Brands = ({ data }) => {
  const { locale } = useRouter();
  const [swiper, set_swiper] = useState(false);

  const activeData = data?.value?.filter((item) => item?.active);

  return (
    activeData?.length > 0 && (
      <>
        <ProductTitle
          title={locale === 'en' ? 'Our Brands' : 'العلامات التجارية'}
          swiper={swiper}
        />
        <Swiper
          key={locale}
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          breakpoints={BrandBreakPoint}
          loop
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onInit={(e) => set_swiper(e)}
        >
          {activeData?.map((brand, idx) => {
            const imgSrc = renderImageHandler(brand, locale);

            return (
              <SwiperSlide key={idx}>
                <Link href={`/${brand?.url}`}>
                  <Image
                    src={imgSrc}
                    className='object-contain w-full h-auto p-1 cursor-pointer max-h-16 min-h-16'
                    loading='lazy'
                    alt={brand?.[`title-${locale}`]}
                  />
                </Link>{' '}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </>
    )
  );
};

export default Brands;

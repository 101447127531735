import { MultiBreakPoints, ProductBreakPoints } from '@/constants/BreakPoints';
import { Swiper, SwiperSlide } from 'swiper/react';

import ProductCard from '../cards/ProductCard';
import ProductTitle from './ProductTitle';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useSWRConfig } from 'swr';
import { getProductsByCategoryId } from '@/services/category';
import useSWRMutation from 'swr/mutation';
import { getCookies } from 'cookies-next';
import { Autoplay } from 'swiper/modules';

const Products = ({ data }) => {
  const activeTabs = data?.value?.filter((item) => item?.active);

  const isMulti = activeTabs?.length > 1;

  const { locale } = useRouter();

  const [swiper, set_swiper] = useState({});
  const [currentTab, setCurrentTab] = useState('');

  const { handshake } = getCookies();

  const { trigger, data: tabProducts } = useSWRMutation(
    `/tab/${currentTab}/${locale}`,
    (_, { arg: tab }) =>
      getProductsByCategoryId(
        handshake,
        tab,
        'sortBy=position&sortDir=ASC',
        '',
        12,
        0,
        false
      ),
    { populateCache: true }
  );

  const { cache } = useSWRConfig();

  useEffect(() => {
    const currentTabCache = cache.get(`/tab/${currentTab}/${locale}`);

    if (currentTab && !currentTabCache) {
      trigger(currentTab);
    }
  }, [currentTab, trigger, cache, locale]);

  const changeTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  const products = useMemo(() => {
    const currentTabCache = cache.get(`/tab/${currentTab}/${locale}`);

    const displayedProducts =
      currentTabCache?.data?.data?.data?.products?.length > 0
        ? currentTabCache?.data?.data?.data?.products
        : tabProducts?.data?.data?.products?.length > 0
          ? tabProducts?.data?.data?.products
          : activeTabs?.at(0)?.products || [];

    return displayedProducts;
  }, [
    tabProducts?.data?.data?.products,
    cache,
    currentTab,
    locale,
    activeTabs,
  ]);

  console.log({Titleeeeeeeeee: data?.[`title-${locale}`]})

  return (
    activeTabs?.length > 0 && (
      <>
        <ProductTitle
          title={data?.[`title-${locale}`] || (locale === 'en' ? "Shop By Products" : "تسوق حسب المنتجات")}
          isMulti={isMulti}
          tabs={activeTabs}
          changeTabHandler={changeTabHandler}
          swiper={swiper}
        />
        <Swiper
          breakpoints={isMulti ? MultiBreakPoints : ProductBreakPoints}
          key={locale}
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          loop
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onInit={(e) => set_swiper(e)}
          className='px-0.5'
        >
          {products?.map((product, i) => (
            <SwiperSlide key={i}>
              <ProductCard product={product} isMulti={isMulti} />
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    )
  );
};

export default Products;

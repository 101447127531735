import { useRouter } from 'next/router';
import Image from '../Image';
import Link from 'next/link';
import { renderImageHandler } from '@/helpers/common/renderImage';

const CategoryCard = ({ itemData, fromCategories }) => {
  const { locale } = useRouter();
  const imgSrc = renderImageHandler(itemData, locale);
  return (
    <Link
      href={`/${!fromCategories ? itemData?.['category-url'] : itemData?.url_key}`}
      className='flex flex-col items-center justify-center gap-3 p-2 pt-6 border rounded-lg md:gap-4 md:p-5 md:pt-8'
    >
      <Image
        src={
          !fromCategories
            ? imgSrc
            : itemData?.image || '/imgs/img-placeholder.png'
        }
        className='object-contain w-auto h-auto max-h-10 min-h-10 min-w-10 max-w-10 sm:max-h-14 sm:min-h-14 sm:min-w-14 sm:max-w-14'
        loading='eager'
        alt={!fromCategories ? itemData?.[`title-${locale}`] : itemData?.name || ''}
      />
      <span className='text-xs font-semibold text-center max-sm:w-full max-sm:truncate sm:text-base md:min-h-12 md:text-lg'>
        {!fromCategories ? itemData?.[`title-${locale}`] : itemData?.name || ''}
      </span>
    </Link>
  );
};

export default CategoryCard;

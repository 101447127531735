import Brands from '@/components/home/Brands';
import Categories from '@/components/home/Categories';
import GridSwiper from '@/components/home/GridSwiper';
import MainBanners from '@/components/home/MainBanners';
import Products from '@/components/home/Products';
import PromoProducts from '@/components/home/PromoProducts';
import StaticBanners from '@/components/home/StaticBanners';
import { getProductsByCategoryId } from '@/services/category';

export const getTabsData = async (handshake, tabs) => {
  if ('value' in tabs) {
    const firstTab = await getProductsByCategoryId(
      handshake,
      tabs?.value?.at(0)?.id,
      'sortBy=position&sortDir=ASC',
      '',
      12,
      0,
      false
    );
    const tabsProducts = tabs.value.map((tab, idx) =>
      idx === 0
        ? {
            ...tab,
            products: firstTab.data?.data?.products || [],
          }
        : { ...tab }
    );
    return { ...tabs, value: tabsProducts };
  }
  return tabs;
};

export const renderHomeSection = (sectionType, data) => {
  switch (sectionType) {
    case 'first-section':
      return <MainBanners data={data} />;
    case 'flash-sale':
      return <PromoProducts data={data} />;
    case 'products-list':
      return <Products data={data} />;
    case 'products-grid':
      return <GridSwiper data={data} />;
    case 'categories':
      return <Categories data={data} />;
    case 'brands':
      return <Brands data={data} />;
    case 'two-main-banners':
    case 'one-second-banners':
      return <StaticBanners data={data} />;
    default:
      return null;
  }
};

import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';

import PromoCard from '../cards/PromoCard';
import ProductTitle from './ProductTitle';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Autoplay } from 'swiper/modules';

const PromoProducts = ({ data }) => {
  const { locale } = useRouter();
  const { t } = useTranslation('home');
  const [swiper, set_swiper] = useState(false);

  const activeData = data?.value?.filter((item) => item?.active);
  const products = activeData?.[0]?.products;

  return (
    activeData?.length > 0 && (
      <>
        <ProductTitle
          title={t('Promo Products')}
          showMoreLink={`/${activeData?.[0]?.url}`}
          swiper={swiper}
        />
        <Swiper
          key={locale}
          dir={locale === 'en' ? 'ltr' : 'rtl'}
          breakpoints={{
            320: { slidesPerView: 1.4, spaceBetween: 20 },
            1024: { slidesPerView: 2, spaceBetween: 25 },
          }}
          loop
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onInit={(e) => set_swiper(e)}
        >
          {products?.map((product, i) => (
            <SwiperSlide key={i}>
              <PromoCard product={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    )
  );
};

export default PromoProducts;

import { useRouter } from 'next/router';
import Image from '../Image';
import HomeBannerSwiper from '../global/swiper/HomeBannerSwiper';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import Link from 'next/link';
import { renderImageHandler } from '@/helpers/common/renderImage';

const MainBanners = ({ data }) => {
  const { locale } = useRouter();

  const activeMainBanners = data?.main_banners?.value?.filter(
    (item) => item?.active
  );
  const activeMainSliders = data?.main_sliders?.value
    ?.filter((item) => item?.active)
    ?.toSorted((a, b) => +a.position - b.position);

  return (
    <div className='grid grid-cols-12 items-start gap-5 sm:my-5'>
      <Link
        href={handleLinkByType(
          activeMainBanners?.[0]?.url,
          activeMainBanners?.[0]?.type
        )}
        className='h-full relative col-span-full max-sm:hidden sm:col-span-4 md:col-span-3 lg:col-span-2'
      >
        <Image
          loading='eager'
          src={renderImageHandler(activeMainBanners?.[0], locale)}
          className='h-full max-h-[504px] w-full rounded-xl object-cover'
          alt='Banner 1'
        />
        <div className='absolute left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-around rounded-xl bg-black bg-opacity-45'>
          <div className='text-center text-white'>
            {activeMainBanners?.[0]?.[`title-${locale}`] && (
              <p className='mb-3 text-base font-medium'>
                {activeMainBanners?.[0]?.[`title-${locale}`]}
              </p>
            )}
            {activeMainBanners?.[0]?.[`subtitle-${locale}`] && (
              <p className='mb-3 text-xl font-medium'>
                {activeMainBanners?.[0]?.[`subtitle-${locale}`]}
              </p>
            )}
            {activeMainBanners?.[0]?.[`description-${locale}`] && (
              <p className='text-xl font-medium'>
                {activeMainBanners?.[0]?.[`description-${locale}`]}
              </p>
            )}
          </div>
          {activeMainBanners?.[0]?.[`button-${locale}`] && (
            <Link
              href={handleLinkByType(
                activeMainBanners?.[0]?.url,
                activeMainBanners?.[0]?.type
              )}
              className='inline-block rounded-md bg-white p-3 text-sm font-medium text-primary'
            >
              {activeMainBanners?.[0]?.[`button-${locale}`]}
            </Link>
          )}
        </div>
      </Link>
      <div className='col-span-full grid grid-cols-3 gap-5 sm:col-span-8 md:col-span-9 lg:col-span-10'>
        <div className='relative col-span-full'>
          <HomeBannerSwiper sliders={activeMainSliders} />
        </div>
        <div className='col-span-full flex grid-cols-3 gap-4 max-sm:overflow-x-auto sm:grid'>
          {activeMainBanners?.slice(1).map((banner, i) => {
            const imgSrc = renderImageHandler(banner, locale);

            return (
              <Link
                key={i}
                href={handleLinkByType(banner?.url, banner?.type)}
                className='relative max-sm:min-w-64 max-sm:max-w-64'
              >
                <Image
                  loading='eager'
                  src={imgSrc}
                  className='h-36 w-full rounded-xl object-cover sm:h-[154px]'
                  alt='Banner 1'
                />
                <div className='absolute start-0 top-0 z-50 h-full w-full rounded-xl bg-black bg-opacity-45'>
                  <div className='ps-4 pt-5 text-white'>
                    <p className='mb-3 text-base'>
                      {banner?.[`title-${locale}`]}
                    </p>
                    <p className='text-xl font-medium'>
                      {banner?.[`subtitle-${locale}`]}
                    </p>
                    <p className='text-xl font-medium'>
                      {banner?.[`description-${locale}`]}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MainBanners;

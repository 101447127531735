import { useRouter } from 'next/router';

import Image from '../Image';
import AddToCart from '../global/buttons/AddToCart';
import AddtoWishlist from '../global/buttons/AddtoWishlist';
import SpecialOffer from '../product/Details/SpecialOffer';
import Link from 'next/link';
import { countDownHandler } from '@/utils/global';
import { validatePrice } from '@/helpers/product';

const PromoCard = ({ product }) => {
  const { locale } = useRouter();

  const price = validatePrice(product?.prices_with_tax);

  return (
    <div className='flex gap-5 max-md:flex-col'>
      <Image
        src={product?._media_?.image?.at(0)?.image || ''}
        className='h-auto rounded-lg border object-contain max-md:w-full max-sm:max-h-[185px] md:h-[270px] md:w-[270px]'
        alt=''
        loading='lazy'
      />
      <div className='flex flex-1 flex-col gap-2'>
        <Link
          href={`/product/${product?.rewrite_url}`}
          className='line-clamp-2 min-h-10 text-sm sm:line-clamp-1'
        >
          {product?.name?.[0] || ''}
        </Link>
        <p className='text-xs text-dark-gray'>
          {product?._category_?.main_category?.name}
        </p>
        <div className='flex items-center gap-3'>
          <p className='text-lg font-semibold'>
            {price?.specialPrice || price?.originalPrice}{' '}
            {locale === 'en' ? 'SAR' : 'ر.س'}
          </p>
          {price?.specialPrice && (
            <p className='text-xs text-dark-gray line-through'>
              {price?.originalPrice} {locale === 'en' ? 'SAR' : 'ر.س'}
            </p>
          )}
        </div>
        {product?.prices_with_tax &&
          countDownHandler(
            product?.prices_with_tax?.discounted_price_to,
            product?.prices_with_tax?.discounted_price_from
          )?.isCountDown && <SpecialOffer product={product} fromPromo />}
        <div className='mt-3 flex items-center gap-2'>
          <AddToCart fromPromo product={product} />
          <AddtoWishlist fromPromo product={product} />
        </div>
      </div>
    </div>
  );
};

export default PromoCard;
